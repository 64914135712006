export const LOADING = 'LOADING'
export const LOADING_SUCCESS = 'LOADING_SUCCESS'

export const ERROR = 'ERROR'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS'

export const CHECK_LANGUAGE = 'CHECK_LANGUAGE'
export const CHECK_LANGUAGE_SUCCESS = 'CHECK_LANGUAGE_SUCCESS'

export const CallApiWs = 'CallApiWs'

export const API_UploadFile='API_UploadFile'

export const API_spCallServerSystem = 'API_spCallServerSystem'

export const API_spCallServerChat = 'API_spCallServerChat'