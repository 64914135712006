import { combineReducers } from "redux";

import MainActionReducer from "./MainAction";
import LocationReducer from "./Location";
const rootReducer = combineReducers({
    MainAction:MainActionReducer,
    Location:LocationReducer
});

export default rootReducer;
